import {required} from "vuelidate/lib/validators";
import {zipCode} from "@/plugins/vuelidate";

export const createAddressValidation = () => ({
    street: {required},
    houseNumber: {required},
    // flatNumber: {required},
    city: {required},
    zipCode: {required},
    country: {required},
});
