




import moment from 'moment';
import Vue from 'vue';
import Component from 'vue-class-component';
import SygniInfoBox from '@/components/layout/SygniInfoBox.vue';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniModal from '@/components/layout/SygniModal.vue';
import { CreateFundDTO } from '../../store/types';
import { UserContext } from '@/modules/genprox/models/User';
import { mapState } from 'vuex';
import { DropdownImageItem } from '@/components/sygni-dropdown/SygniDropdown.vue';

@Component({
  components: {
    SygniInfoBox, SygniLinkButton, SygniModal
  },
  computed: {
    ...mapState('genprox', {
      userContexts: (state: any) => state.userContexts,
    }),
  },
})
export default class AddNewFundBoilerplate extends Vue {
  newFund: CreateFundDTO = { registrationAddress: {}, employee: { managementTeam: false } } as CreateFundDTO;
  showLegalEntityNotification: boolean = false;
  showConfirmationModal: boolean = false;
  legalEntitiesList: any[] = []
  userContexts!: UserContext[];
  isLoading: boolean = false;

  get dropdownUserContexts(): DropdownImageItem<UserContext>[] {
    if (this.userContexts?.length === 0) return null;
    return this.userContexts.map<DropdownImageItem<UserContext>>((userContext: any) => {
      return userContext?.id
    })
  }

  get foundedLegalEntity() {
    return this.legalEntitiesList?.find((el: any) => el.taxNumber === this.newFund?.taxNumber)
  }

  get hasLegalEntityAlready() {
    return !!(this.foundedLegalEntity) && this.showLegalEntityNotification
  }

  get isLegalEntityAlreadyUsed() {
    return this.dropdownUserContexts.includes(this.foundedLegalEntity?.id)
  }

  closeConfirmationModal() {
    this.showConfirmationModal = false
  }

  openConfirmationModal() {
    this.showConfirmationModal = true
  }

  getEstablishmentDate(dateString: string) {
    const date = moment(dateString, 'DD.MM.YYYY')?.format('YYYY-MM-DD')

    return date
  }

  getEndOfFinancialPeriod(dateString: string) {
    const date = moment(dateString, 'DD.MM.YYYY')?.format('DD/MM')

    return date
  }

  getCountry(countryName: string) {
    switch (countryName?.toUpperCase()) {
      case 'POLSKA':
        return 'PL'
      default:
        return ''
    }
  }

  getLegalForm(legalForm: string) {
    switch (legalForm?.toUpperCase()) {
      case 'SPÓŁKA PARTNERSKA':
        return 'partnership'
      case 'SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ':
        return 'limited_liability_company'
      case 'SPÓŁKA AKCYJNA':
        return 'joint_stock_company'
      case 'SPÓŁKA JAWNA':
        return 'general_partnership'
      case 'SPÓŁKA KOMANDYTOWO-AKCYJNA':
        return 'limited_joint_stock_partnership'
      case 'SPÓŁKA KOMANDYTOWA':
        return 'limited_partnership'
      default:
        return ''
    }
  }

  goToSelectedLegalEntity() {
    const selectedContext = this.userContexts?.find((el: any) => el.id === this.foundedLegalEntity?.id)

    if (selectedContext) {
      this.$root.$emit('setUserContext', selectedContext, this.$route?.query?.id ? `/fund/accept-package/${this.$route?.query?.id}` : '/welcome')
    } else {
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: 'Something went wrong. Please try again later.'
      })
    }
  }

  async handleAddFundAction() {
    this.$v.$touch();
    if (!this.$v.$error) {
      if (this.hasLegalEntityAlready) {
        this.openConfirmationModal()
      } else {
        await this.addFund()
      }
    }
  }

  async addFund(): Promise<void> {}

  async joinSelectedLegalEntity() {
    this.closeConfirmationModal()
    this.isLoading = true
    try {
      await this.$store.dispatch('genprox/askToJoinFund', this.foundedLegalEntity?.id);
      this.$notify({
        duration: 3000,
        type: 'success',
        title: 'Success',
        text: 'Request sent successfully.'
      })
      this.$router.push('/welcome');
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
    this.isLoading = false
  }

  async loadDataFromNip(e: any) {
    const value = e?.target?.value || ''
    this.showLegalEntityNotification = false

    if (value?.length === 10) {
      this.isLoading = true

      try {
        const date: string = moment().format('YYYY-MM-DD')
        const { data: response }: any = await this.$axios.get(`https://wl-api.mf.gov.pl/api/search/nip/${value}?date=${date}`)

        const registerNumber = response?.result?.subject?.krs
        if (registerNumber && registerNumber?.length === 10) {
          this.$set(this.newFund, 'registerNumber', registerNumber)
          await this.loadDataFromKrs(registerNumber, false)
        }
      } catch (e) {
        e;
      }

      this.showLegalEntityNotification = true
      this.isLoading = false
    }
  }

  async loadDataFromKrs(e: any, useEvent: boolean = true) {
    const value = (useEvent) ? e?.target?.value || '' : e || ''
    if (value?.length === 10) {
      this.isLoading = true
      try {
        const { data: krsData }: any = await this.$axios.get(`https://api-krs.ms.gov.pl/api/krs/OdpisPelny/${value}?rejestr=P&format=json`)
  
        this.$nextTick(() => {
          const lastNameIndex = krsData?.odpis?.dane?.dzial1?.danePodmiotu?.nazwa?.length
          this.$set(this.newFund, 'name', krsData?.odpis?.dane?.dzial1?.danePodmiotu?.nazwa[lastNameIndex - 1]?.nazwa || '')
          const lastTaxNumberIndex = krsData?.odpis?.dane?.dzial1?.danePodmiotu?.identyfikatory?.length
          this.$set(this.newFund, 'taxNumber', krsData?.odpis?.dane?.dzial1?.danePodmiotu?.identyfikatory[lastTaxNumberIndex - 1]?.identyfikatory?.nip || '')
          const lastStatisticalNumberIndex = krsData?.odpis?.dane?.dzial1?.danePodmiotu?.identyfikatory?.length
          this.$set(this.newFund, 'statisticalNumber', krsData?.odpis?.dane?.dzial1?.danePodmiotu?.identyfikatory[lastStatisticalNumberIndex - 1]?.identyfikatory?.regon || '')
  
          // TO DO: mapping here
          const lastLegalFormIndex = krsData?.odpis?.dane?.dzial1?.danePodmiotu?.formaPrawna?.length
          this.$set(this.newFund, 'legalForm', this.getLegalForm(krsData?.odpis?.dane?.dzial1?.danePodmiotu?.formaPrawna[lastLegalFormIndex - 1]?.formaPrawna))
  
          this.$set(this.newFund, 'establishmentDate', this.getEstablishmentDate(krsData?.odpis?.naglowekP?.wpis[0]?.dataWpisu))
          const lastFinancialPeriodIndex = krsData?.odpis?.dane?.dzial3?.informacjaODniuKonczacymRokObrotowy?.dzienKonczacyPierwszyRokObrotowy?.length
          this.$set(this.newFund, 'endOfFinancialPeriod', this.getEndOfFinancialPeriod(krsData?.odpis?.dane?.dzial3?.informacjaODniuKonczacymRokObrotowy?.dzienKonczacyPierwszyRokObrotowy[lastFinancialPeriodIndex - 1]?.dzienKonczacyPierwszyRokObrotowy))
  
          const lastAddressIndex = krsData?.odpis?.dane?.dzial1?.siedzibaIAdres?.adres?.length
          this.$set(this.newFund.registrationAddress, 'street', krsData?.odpis?.dane?.dzial1?.siedzibaIAdres?.adres[lastAddressIndex - 1]?.ulica || '')
          this.$set(this.newFund.registrationAddress, 'houseNumber', krsData?.odpis?.dane?.dzial1?.siedzibaIAdres?.adres[lastAddressIndex - 1]?.nrDomu || '')
          this.$set(this.newFund.registrationAddress, 'flatNumber', krsData?.odpis?.dane?.dzial1?.siedzibaIAdres?.adres[lastAddressIndex - 1]?.nrLokalu || '')
          this.$set(this.newFund.registrationAddress, 'city', krsData?.odpis?.dane?.dzial1?.siedzibaIAdres?.adres[lastAddressIndex - 1]?.miejscowosc || '')
          this.$set(this.newFund.registrationAddress, 'country', this.getCountry(krsData?.odpis?.dane?.dzial1?.siedzibaIAdres?.adres[lastAddressIndex - 1]?.kraj))
          this.$set(this.newFund.registrationAddress, 'zipCode', krsData?.odpis?.dane?.dzial1?.siedzibaIAdres?.adres[lastAddressIndex - 1]?.kodPocztowy || '')
        })
      } catch (e) {
        e;
      }
      this.showLegalEntityNotification = true
      this.isLoading = false
    }
  }

  async beforeMount() {
    this.$store.dispatch('dictionary/getLegalEntitySelects');
    this.legalEntitiesList = await this.$store.dispatch('investors/getLegalEntitiesList')
  }
}
